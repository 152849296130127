export const questions = [
  {
    question: 'តើអាជីវកម្មរបស់អ្នកបានដំណើរការរយៈពេលប៉ុន្មាន?',
    correctIndex: 3,
    answers: [
      { value: 'ក', label: `ខ្ញុំ​មិន​ទាន់​បើក​អាជីវកម្ម​របស់​ខ្ញុំ​នៅ​ឡើយ​ទេ។`, index: 0 },
      { value: 'ខ', label: 'តិចជាង 1 ឆ្នាំ។', index: 1 },
      { value: 'គ', label: '1-2 ឆ្នាំ។', index: 2 },
      { value: 'ឃ', label: '3+ ឆ្នាំ។', index: 3 },
    ],
  },
  {
    question: 'តើអ្នកមានឈ្មោះអាជីវកម្ម និងអង្គភាពទេ?',
    correctIndex: 0,
    answers: [
      { value: 'ក', label: 'បាទ', index: 0 },
      { value: 'ខ', label: 'ទេ', index: 1 },
    ],
  },
  {
    question: 'តើអ្នកមានផែនការអាជីវកម្មទេ?',
    correctIndex: 0,
    answers: [
      { value: 'ក', label: 'បាទ', index: 0 },
      { value: 'ខ', label: 'ទេ', index: 1 },
    ],
  },
  {
    question: 'តើអ្នកមានថវិកាអាជីវកម្មនៅនឹងកន្លែងទេ?',
    correctIndex: 0,
    answers: [
      { value: 'ក', label: 'បាទ', index: 0 },
      { value: 'ខ', label: 'ទេ', index: 1 },
    ],
  },
  {
    question: 'តើអ្នកបានកំណត់គោលដៅហិរញ្ញវត្ថុសម្រាប់អាជីវកម្មរបស់អ្នកហើយឬនៅ?',
    correctIndex: 0,
    answers: [
      { value: 'ក', label: 'បាទ', index: 0 },
      { value: 'ខ', label: 'ទេ', index: 1 },
    ],
  },
  {
    question: 'តើអ្នកបានធានាហិរញ្ញវត្ថុចាំបាច់សម្រាប់អាជីវកម្មរបស់អ្នកទេ?',
    correctIndex: 0,
    answers: [
      { value: 'ក', label: 'បាទ', index: 0 },
      { value: 'ខ', label: 'ទេ', index: 1 },
    ],
  },
  {
    question: 'តើអាជីវកម្មរបស់អ្នកទទួលបានប្រាក់ចំណេញទេ?',
    correctIndex: 0,
    answers: [
      { value: 'ក', label: 'បាទ', index: 0 },
      { value: 'ខ', label: 'ទេ', index: 1 },
    ],
  },
  {
    question:
      'តើអ្នកមានគោលដៅសម្រាប់ពង្រីកអាជីវកម្មរបស់អ្នក ហើយដឹងពីរបៀបដើម្បីសម្រេចបានពួកគេទេ?',
    correctIndex: 0,
    answers: [
      { value: 'ក', label: 'បាទ', index: 0 },
      { value: 'ខ', label: 'ទេ', index: 1 },
    ],
  },
];

export const suggestions = [
  {
      "lowScore": 0,
      "highScore": 3,
      "title": "ដំណាក់កាលទី 1",
      "text": "អ្នកគឺជាម្ចាស់អាជីវកម្មថ្មីដែលមានសក្តានុពលជាច្រើន — សូមពិនិត្យមើលធនធានទាំងនេះ ដើម្បីជួយអ្នកក្នុងការកសាង និងរីកចម្រើន។",
      "resources": [
        {
            "text": "តើ​ការ​ចាប់​ផ្តើ​ម​អាជីវកម្ម​ត្រឹមត្រូវ​សម្រាប់​អ្នក​?",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
        },
        {
            "text": "ការបង្កើតផែនការអាជីវកម្ម",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
        },
        {
            "text": "ការបង្កើតអាជីវកម្ម",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
        }
    ]
  },
  {
      "lowScore": 4,
      "highScore": 6,
      "title": "ដំណាក់កាលទី 2",
      "text": "អ្នកស្ថិតក្នុងដំណាក់កាលដំបូងនៃអាជីវកម្មរបស់អ្នក — សូមពិនិត្យមើលធនធានទាំងនេះ ដើម្បីជួយអ្នកក្នុងការគ្រប់គ្រងប្រតិបត្តិការរបស់អ្នក។",
      "resources": [
        {
            "text": "មូលដ្ឋានគ្រឹះហិរញ្ញវត្ថុ",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
        },
        {
            "text": "ការគ្រប់គ្រង​ហិរញ្ញវត្ថុ",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-managing-business"
        },
        {
            "text": "ការលើកកម្ពស់អាជីវកម្ម",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-managing-business"
        }
    ]
  },
  {
      "lowScore": 7,
      "highScore": 8,
      "title": "ដំណាក់កាលទី 3",
      "text": "អ្នកជាម្ចាស់អាជីវកម្មដែលបានបង្កើតឡើង — សូមពិនិត្យមើលធនធានទាំងនេះ ដើម្បីជួយអ្នកក្នុងការបណ្តាក់ទុនរបស់អ្នកបន្ថែមទៀត។",
      "resources": [
        {
            "text": "ការវាស់វែងជោគជ័យ",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        },
        {
            "text": "និរន្តរភាព​បរិស្ថាន",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        },
        {
            "text": "ការធ្វើផែនការសម្រាប់គ្រាអាសន្ន",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        }
    ]
  }
]
