import logo from './logo.svg';
import {Routes, Route} from 'react-router-dom'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Questionnaire from './components/questionnaire';
import QuestionnaireMY from './components/questionnaire-my';
import QuestionnaireID from './components/questionnaire-id';
import QuestionnaireKH from './components/questionnaire-kh';
function App() {
  return (
    <Routes>
      <Route path='/' element={<Questionnaire/>}/>    
      <Route path='bahasa-melayu' element={<QuestionnaireMY/>}/>
      <Route path='bahasa-indonesia' element={<QuestionnaireID/>}/>
      <Route path='khmer' element={<QuestionnaireKH/>}/>
    </Routes>

  );
}

export default App;
