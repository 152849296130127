export const questions = [
  {
    question: 'How long has your business been in operation?',
    correctIndex: 3,
    answers: [
      { value: 'A', label: `I haven't launched my business yet`, index: 0 },
      { value: 'B', label: 'Less than 1 year', index: 1 },
      { value: 'C', label: '1-2 years', index: 2 },
      { value: 'D', label: '3+ years', index: 3 },
    ],
  },
  {
    question: 'Do you have a business name and entity?',
    correctIndex: 0,
    answers: [
      { value: 'A', label: 'Yes', index: 0 },
      { value: 'B', label: 'No', index: 1 },
    ],
  },
  {
    question: 'Do you have a business plan?',
    correctIndex: 0,
    answers: [
      { value: 'A', label: 'Yes', index: 0 },
      { value: 'B', label: 'No', index: 1 },
    ],
  },
  {
    question: 'Do you have a business budget in place?',
    correctIndex: 0,
    answers: [
      { value: 'A', label: 'Yes', index: 0 },
      { value: 'B', label: 'No', index: 1 },
    ],
  },
  {
    question: 'Have you identified financial goals for your business?',
    correctIndex: 0,
    answers: [
      { value: 'A', label: 'Yes', index: 0 },
      { value: 'B', label: 'No', index: 1 },
    ],
  },
  {
    question: 'Have you secured the necessary financing for your business?',
    correctIndex: 0,
    answers: [
      { value: 'A', label: 'Yes', index: 0 },
      { value: 'B', label: 'No', index: 1 },
    ],
  },
  {
    question: 'Is your business making a profit?',
    correctIndex: 0,
    answers: [
      { value: 'A', label: 'Yes', index: 0 },
      { value: 'B', label: 'No', index: 1 },
    ],
  },
  {
    question:
      'Do you have goals for scaling your business, and know how to achieve them?',
    correctIndex: 0,
    answers: [
      { value: 'A', label: 'Yes', index: 0 },
      { value: 'B', label: 'No', index: 1 },
    ],
  },
];

export const suggestions = [
  {
      "lowScore": 0,
      "highScore": 3,
      "title": "Stage 1",
      "text": "You're the owner of a new business with lots of potential — check out these resources to help you build and grow.",
      "resources": [
        {
            "text": "Is Starting a Business Right for You?",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
        },
        {
            "text": "Creating a Business Plan",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
        },
        {
            "text": "Creating a Business",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
        }
    ]
  },
  {
      "lowScore": 4,
      "highScore": 6,
      "title": "Stage 2",
      "text": "You're in the early stages of your business — check out these resources to help you manage your operations.",
      "resources": [
        {
            "text": "Financial Basics",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
        },
        {
            "text": "Financial Management",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-managing-business"
        },
        {
            "text": "Promoting a Business",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-managing-business"
        }
    ]
  },
  {
      "lowScore": 7,
      "highScore": 8,
      "title": "Stage 3",
      "text": "You're the owner of an established business — check out these resources to help you take your venture even further.",
      "resources": [
        {
            "text": "Measuring Success",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        },
        {
            "text": "Environmental Sustainability",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        },
        {
            "text": "Planning for Emergencies",
            "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        }
    ]
  }
]
