import React, { useState, useEffect } from 'react';
import './index.css';
import { questions, suggestions } from './data';

const Questionnaire = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(questions.length).fill(null));
  const [score, setScore] = useState(0);
  const [begin, setBegin] = useState(false);
  const [addedAPoint, setaddedAPoint] = useState(false);
  const choices = ['A', 'B', 'C', 'D'];
  const handleAnswerChange = (event) => {
    if(event.target.value.index === questions[currentQuestionIndex].correctIndex){
      if(!addedAPoint){
        setScore(prevScore => prevScore + 1)
        setaddedAPoint(true)
      }
    } else {
      if(addedAPoint){
        setScore(prevScore => prevScore - 1)
        setaddedAPoint(false)
      }
    }
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestionIndex] = event.target.value;
    setAnswers(updatedAnswers);
  };

  const handleSubmit = (event) => {
    setaddedAPoint(false)
    event.preventDefault();
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };
  const resetAll = () => {
    setAnswers([])
    setBegin(false);
    setCurrentQuestionIndex(0);
    setScore(0)
  }
  useEffect(() => {
    console.log(`Total points: ${score}`)
  },[score])
  const renderIntro = () => {
    return (
      <>
        <div className='row'>
          <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12 right-padding-100'>
            <div className='quiz2-intro-title'>
              Di mana Anda berada  <br />
              dalam siklus bisnis?
            </div>
          </div>
          <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
            <div className='quiz2-intro-text'>
            Jawab pertanyaan ini dan dapatkan tautan ke tips bisnis ahli dan konten khusus untuk tahap bisnis Anda. Baik Anda pemilik berpengalaman atau baru dalam permainan, ini adalah cara yang sempurna untuk mempersiapkan usaha baru Anda - atau mengambil bisnis Anda ke level berikutnya.
            </div>
            <div className='quiz2-intro-buttons'>
              <button
                type='button'
                className='quiz2-button selected'
                onClick={() => {
                  setBegin(true);
                }}>
                Mulai
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];
    const isDisabled = answers[currentQuestionIndex] === null;
    return (
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
            <div className='quiz2-main-title'>{currentQuestion.question}</div>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 quiz2-answers-group'>
            <div className='quiz2-answers'>
              {currentQuestion.answers.map((item, index) => (
                <div
                  key={`${item.label}${item.value}`}
                  className={`quiz2-answer ${
                    answers.includes(item) ? 'selected' : ''
                  }`}
                  onClick={() =>
                    handleAnswerChange({ target: { value: item } })
                  }>
                  <div className='quiz2-answer-letter'>{choices[index]}</div>
                  <div className='quiz2-answer-text'>{item.label}</div>
                </div>
              ))}
            </div>
            <div className='quiz2-main-buttons'>
              <button
                className={`quiz2-button disabled ${
                  isDisabled ? '' : 'selected'
                }`}
                type='submit'
                disabled={isDisabled}>
                Kirim
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  const renderResult = () => {
    const suggestion = suggestions.filter(suggestion => suggestion.lowScore <= score && suggestion.highScore >= score)[0];
    console.log('SCORE: ', score);
    console.log('SUGGESTION', suggestion)
    return (
      <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
          <div className='quiz2-result-title'>{suggestion.title}</div>
          <div className='quiz2-result-text'>{suggestion.text}</div>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
          <div className='quiz2-resources'>
            <hr className='quiz2-resource-line' />
            {suggestion.resources.map((item, index) => (
              <div key={index}>
                <a className='quiz2-resource' href={item.url} target='_blank'>
                  {item.text}
                </a>
                <hr className='quiz2-resource-line' />
              </div>
            ))}
          </div>
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'></div>
        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
          {' '}
          <div className='quiz2-result-buttons'>
            {' '}
            <button className='quiz2-button selected' type='button' onClick={() => {resetAll()}}>
            ULANGI KUISIONER
            </button>
            <div className='quiz2-result-social'>
              <a
                href='https://www.facebook.com/sharer.php?s=100&amp;p[url]=https://www.practicalbusinessskills.com/&amp;p[title]=Quick%20Questionnaire'
                target="_blank">
                <img src='https://www.practicalbusinessskills.com/assets/js/questionnaire/js/quiz_v2/res/results_facebook.png' />
              </a>
              <a
                href='https://twitter.com/intent/tweet?url=https://www.practicalbusinessskills.com/&amp;text=Quick%20Questionnaire&amp;via=YOURTWITTERACCOUNTNAME'
                target="_blank">
                <img src='https://www.practicalbusinessskills.com/assets/js/questionnaire/js/quiz_v2/res/results_twitter.png' />
              </a>
            </div>
          </div>
        </div>
        {/* <h3>{suggestion}</h3> */}
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: '#2e55b0',
        color: 'white',
      }}
      className="container-body">
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className='quiz2-header-title'>Kuisioner Cepat</div>
          </div>
          <div className='col'>
            {currentQuestionIndex < questions.length && (
              <>
                <div className='quiz2-header-number'>
                  {!begin ? (
                    <>
                      <span className='quiz2-circle'>{questions.length}</span>{' '}
                      Pertanyaan
                    </>
                  ) : (
                    <>
                      {currentQuestionIndex + 1} of {questions.length}
                    </>
                  )}
                </div>
                {begin && (
                  <div className='quiz2-gauge quiz2-xs-hide'>
                    <div
                      className='quiz2-gauge-solid'
                      style={{
                        width: `${Math.round(
                          ((currentQuestionIndex + 1) / questions.length) * 100
                        )}%`,
                      }}></div>
                    <div
                      className='quiz2-gauge-dotted'
                      style={{
                        width: `${
                          100 -
                          Math.round(
                            ((currentQuestionIndex + 1) / questions.length) *
                              100
                          )
                        }%`,
                      }}></div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {!begin
          ? renderIntro()
          : currentQuestionIndex < questions.length
          ? renderQuestion()
          : renderResult()}
      </div>
    </div>
  );
};

export default Questionnaire;
