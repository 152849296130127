export const questions = [
  {
    question: 'Berapa lama bisnis Anda telah beroperasi?',
    correctIndex: 3,
    answers: [
    { value: 'A', label: "'Saya belum meluncurkan bisnis saya'", index: 0 },
    { value: 'B', label: 'Kurang dari 1 tahun', index: 1 },
    { value: 'C', label: '1-2 tahun', index: 2 },
    { value: 'D', label: '3 tahun atau lebih', index: 3 },
    ],
    },
    {
      question: 'Apakah Anda memiliki nama bisnis dan entitas?',
      correctIndex: 0,
      answers: [
      { value: 'A', label: 'Ya', index: 0 },
      { value: 'B', label: 'Tidak', index: 1 },
      ],
      },
      {
        question: 'Apakah Anda memiliki rencana bisnis?',
        correctIndex: 0,
        answers: [
        { value: 'A', label: 'Ya', index: 0 },
        { value: 'B', label: 'Tidak', index: 1 },
        ],
        },
        {
          question: 'Apakah Anda memiliki anggaran bisnis yang tersedia?',
          correctIndex: 0,
          answers: [
          { value: 'A', label: 'Ya', index: 0 },
          { value: 'B', label: 'Tidak', index: 1 },
          ],
          },
          {
            question: 'Apakah Anda telah mengidentifikasi tujuan keuangan untuk bisnis Anda?',
            correctIndex: 0,
            answers: [
            { value: 'A', label: 'Ya', index: 0 },
            { value: 'B', label: 'Tidak', index: 1 },
            ],
            },
            {
            question: 'Apakah Anda telah mengamankan pendanaan yang diperlukan untuk bisnis Anda?',
            correctIndex: 0,
            answers: [
            { value: 'A', label: 'Ya', index: 0 },
            { value: 'B', label: 'Tidak', index: 1 },
            ],
            },
            {
              question: 'Apakah bisnis Anda menghasilkan keuntungan?',
              correctIndex: 0,
              answers: [
              { value: 'A', label: 'Ya', index: 0 },
              { value: 'B', label: 'Tidak', index: 1 },
              ],
              },
              {
              question:
              'Apakah Anda memiliki tujuan untuk memperbesar bisnis Anda, dan tahu bagaimana mencapainya?',
              correctIndex: 0,
              answers: [
              { value: 'A', label: 'Ya', index: 0 },
              { value: 'B', label: 'Tidak', index: 1 },
              ],
              },
];

export const suggestions = [
  {
    "lowScore": 0,
    "highScore": 3,
    "title": "Tahap 1",
    "text": "Anda adalah pemilik bisnis baru dengan potensi besar - lihat sumber daya ini untuk membantu Anda membangun dan tumbuh.",
    "resources": [
    {
    "text": "Apakah Memulai Bisnis Sesuai untuk Anda?",
    "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
    },
    {
    "text": "Membuat Rencana Bisnis",
    "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
    },
    {
    "text": "Membuat Bisnis",
    "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
    }
    ]
    },
    {
      "lowScore": 4,
      "highScore": 6,
      "title": "Tahap 2",
      "text": "Anda berada dalam tahap awal bisnis Anda - cek sumber daya ini untuk membantu Anda mengelola operasi Anda.",
      "resources": [
      {
      "text": "Dasar-dasar Keuangan",
      "url": "https://asean-sme-academy.org/id/courses/practical-business-skills-getting-started"
      },
      {
      "text": "Manajemen Keuangan",
      "url": "https://asean-sme-academy.org/id/courses/practical-business-skills-managing-business"
      },
      {
      "text": "Mempromosikan Bisnis",
      "url": "https://asean-sme-academy.org/id/courses/practical-business-skills-managing-business"
      }
      ]
      },
      {
        "lowScore": 7,
        "highScore": 8,
        "title": "Tahap 3",
        "text": "Anda adalah pemilik bisnis yang sudah mapan — lihat sumber daya ini untuk membantu Anda membawa usaha Anda bahkan lebih jauh.",
        "resources": [
        {
        "text": "Mengukur Keberhasilan",
        "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        },
        {
        "text": "Keberlanjutan Lingkungan",
        "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        },
        {
        "text": "Merencanakan untuk Keadaan Darurat",
        "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        }
        ]
        }
]
