export const questions = [
  {
    question: 'Berapa lama perniagaan anda beroperasi?',
    correctIndex: 3,
    answers: [
    { value: 'A', label: 'Saya belum melancarkan perniagaan saya lagi', index: 0 },
    { value: 'B', label: 'Kurang dari 1 tahun', index: 1 },
    { value: 'C', label: '1-2 tahun', index: 2 },
    { value: 'D', label: '3 tahun atau lebih', index: 3 },
    ],
    },
    {
      question: 'Adakah anda mempunyai nama perniagaan dan entiti?',
      correctIndex: 0,
      answers: [
      { value: 'A', label: 'Ya', index: 0 },
      { value: 'B', label: 'Tidak', index: 1 },
      ],
      },
      {
        question: 'Adakah anda mempunyai pelan perniagaan?',
        correctIndex: 0,
        answers: [
        { value: 'A', label: 'Ya', index: 0 },
        { value: 'B', label: 'Tidak', index: 1 },
        ],
        },
        {
          question: 'Adakah anda mempunyai bajet perniagaan yang tersedia?',
          correctIndex: 0,
          answers: [
          { value: 'A', label: 'Ya', index: 0 },
          { value: 'B', label: 'Tidak', index: 1 },
          ],
          },
          {
            question: 'Adakah anda telah mengenal pasti matlamat kewangan untuk perniagaan anda?',
            correctIndex: 0,
            answers: [
            { value: 'A', label: 'Ya', index: 0 },
            { value: 'B', label: 'Tidak', index: 1 },
            ],
            },
            {
              question: 'Adakah anda telah memperoleh pembiayaan yang diperlukan untuk perniagaan anda?',
              correctIndex: 0,
              answers: [
              { value: 'A', label: 'Ya', index: 0 },
              { value: 'B', label: 'Tidak', index: 1 },
              ],
              },
              {
                question: 'Adakah perniagaan anda menghasilkan keuntungan?',
                correctIndex: 0,
                answers: [
                { value: 'A', label: 'Ya', index: 0 },
                { value: 'B', label: 'Tidak', index: 1 },
                ],
                },
                {
                  question: 'Adakah anda mempunyai matlamat untuk meningkatkan perniagaan anda, dan tahu bagaimana untuk mencapainya?',
                  correctIndex: 0,
                  answers: [
                  { value: 'A', label: 'Ya', index: 0 },
                  { value: 'B', label: 'Tidak', index: 1 },
                  ],
                  },
];

export const suggestions = [
  {
    "lowScore": 0,
    "highScore": 3,
    "title": "Tahap 1",
    "text": "Anda adalah pemilik perniagaan baru dengan potensi yang besar - lihat sumber-sumber ini untuk membantu anda membina dan membesarkan perniagaan anda.",
    "resources": [
    {
    "text": "Adakah Memulakan Perniagaan Sesuai untuk Anda?",
    "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
    },
    {
    "text": "Mencipta Pelan Perniagaan",
    "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
    },
    {
    "text": "Mencipta Perniagaan",
    "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
    }
    ]
    },
    {
      "lowScore": 4,
      "highScore": 6,
      "title": "Tahap 2",
      "text": "Anda berada dalam peringkat awal perniagaan anda - lihat sumber-sumber ini untuk membantu anda menguruskan operasi anda.",
      "resources": [
      {
      "text": "Asas Kewangan",
      "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-getting-started"
      },
      {
      "text": "Pengurusan Kewangan",
      "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-managing-business"
      },
      {
      "text": "Mempromosikan Perniagaan",
      "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-managing-business"
      }
      ]
      },
      {
        "lowScore": 7,
        "highScore": 8,
        "title": "Tahap 3",
        "text": "Anda adalah pemilik perniagaan yang mapan - lihat sumber-sumber ini untuk membantu anda membawa perniagaan anda lebih jauh.",
        "resources": [
        {
        "text": "Mengukur Kejayaan",
        "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        },
        {
        "text": "Kesinambungan Alam Sekitar",
        "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        },
        {
        "text": "Merancang untuk Kecemasan",
        "url": "https://asean-sme-academy.org/en/courses/practical-business-skills-building-and-growing"
        }
        ]
        }
]
